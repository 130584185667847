<template>
  <div class="calculator">
    <div class="header">
      <img src="../assets/images/header.png" alt="">
    </div>
    <div class="body">
      <div class="title">
        <h1>2022 VA DISABILITY CALCULATOR</h1>
      </div>
      <div class="step-1">
        <div class="title">
          <h2>STEP 1: ENTER YOUR DISABILITIES USING THE BUTTONS BELOW</h2>
        </div>
        <div class="description">
          <p>A. If your disability is on extremity, select the proper body part below</p>
          <p>B. then select the percentage of disability</p>
          <p>C. if you have a disability on another body part, repeat the steps above for each body part</p>
          <p>If your disability is on an extremity push that proper leg or arm button then push the percentage, if it is not just select the percentage.</p>
        </div>
        <div class="step-1__headline">
          <input type="radio" id="leftLeg" value="LEFT LEG" v-model="picked" />
          <label for="leftLeg"><div class="step-1__headline-item" :class="{active: picked === 'LEFT LEG'}">LEFT LEG</div></label>

          <input type="radio" id="rightLeg" value="RIGHT LEG" v-model="picked" />
          <label for="rightLeg"><div class="step-1__headline-item" :class="{active: picked === 'RIGHT LEG'}">RIGHT LEG</div></label>

          <input type="radio" id="leftArm" value="LEFT ARM" v-model="picked" />
          <label for="leftArm"><div class="step-1__headline-item" :class="{active: picked === 'LEFT ARM'}">LEFT ARM</div></label>

          <input type="radio" id="rightArm" value="RIGHT ARM" v-model="picked" />
          <label for="rightArm"><div class="step-1__headline-item" :class="{active: picked === 'RIGHT ARM'}">RIGHT ARM</div></label>

          <input type="radio" id="body" value="BODY" v-model="picked" />
          <label for="body"><div class="step-1__headline-item" :class="{active: picked === 'BODY'}">BODY</div></label>
        </div>

        <div class="step-1__body">
          <div class="body-row">
            <input type="radio" id="ten" value="10" v-model="percent" />
            <label for="ten"><div class="body-col"><div class="step-1__body-item" :class="{active: percent == 10}"><span></span>10%</div></div></label>

            <input type="radio" id="twenty" value="20" v-model="percent" />
            <label for="twenty"><div class="body-col"><div class="step-1__body-item" :class="{active: percent == 20}"><span></span>20%</div></div></label>

            <input type="radio" id="thirty" value="30" v-model="percent" />
            <label for="thirty"><div class="body-col"><div class="step-1__body-item" :class="{active: percent == 30}"><span></span>30%</div></div></label>

            <input type="radio" id="forty" value="40" v-model="percent" />
            <label for="forty"><div class="body-col"><div class="step-1__body-item" :class="{active: percent == 40}"><span></span>40%</div></div></label>

            <input type="radio" id="fifty" value="50" v-model="percent" />
            <label for="fifty"><div class="body-col"><div class="step-1__body-item" :class="{active: percent == 50}"><span></span>50%</div></div></label>
          </div>
          <div class="body-row">
            <input type="radio" id="sixty" value="60" v-model="percent" />
            <label for="sixty"><div class="body-col"><div class="step-1__body-item" :class="{active: percent == 60}"><span></span>60%</div></div></label>

            <input type="radio" id="seventy" value="70" v-model="percent" />
            <label for="seventy"><div class="body-col"><div class="step-1__body-item" :class="{active: percent == 70}"><span></span>70%</div></div></label>

            <input type="radio" id="eighty" value="80" v-model="percent" />
            <label for="eighty"><div class="body-col"><div class="step-1__body-item" :class="{active: percent == 80}"><span></span>80%</div></div></label>

            <input type="radio" id="ninety" value="90" v-model="percent" />
            <label for="ninety"><div class="body-col"><div class="step-1__body-item" :class="{active: percent == 90}"><span></span>90%</div></div></label>

            <input type="radio" id="hundred" value="100" v-model="percent" />
            <label for="hundred"><div class="body-col"><div class="step-1__body-item" :class="{active: percent == 100}"><span></span>100%</div></div></label>
          </div>
        </div>
        <div class="step-1__footer">
          <div class="text">Disabilities list (click cancel icon to remove)</div>
          <div class="step-1__selected-items">
            <div v-if="percent && picked" @click="closePercentBlock" class="item">{{ percent }}% {{ picked }} <span></span></div>
          </div>
        </div>
      </div>
      <div class="step-2">
        <div class="title">
          <h2>STEP 2</h2>
        </div>
        <div class="step-2__row">
          <div class="step-2__col">
            <div class="children-item">
              <div style="margin-bottom: 38px" class="children-item__title">How many dependent children do you have who are under the age of 18?</div>
              <div class="children-item__count">
                <span @click="minusUnder18AgeCount" class="count-reg"></span>
                <span>{{ under18Count }}</span>
                <span @click="addAgeUnder18Count" class="count-reg">+</span>
              </div>
            </div>
          </div>
          <div class="step-2__col">
            <div class="children-item">
              <div class="children-item__title">How many dependent children do you have who are between the ages of 18 and 24?</div>
              <div class="children-item__count">
                <span @click="minusOver18AgeCount" class="count-reg"></span>
                <span>{{ over18Count }}</span>
                <span @click="addAgeOver18Count" class="count-reg">+</span>
              </div>
            </div>
          </div>
        </div>
        <div class="step-2__row">
          <div class="step-2__col">
                        <div class="marital-status">
                          <div class="marital-status__title">What is your marital status?</div>
                          <div class="marital-status__items">
                            <input type="radio" id="single" value="single" v-model="isMarried" />
                            <label for="single"><div class="item" :class="{active: isMarried === 'single'}"><span></span>Single</div></label>

                            <input type="radio" id="married" value="married" v-model="isMarried" />
                            <label for="married"><div class="item" :class="{active: isMarried === 'married'}"><span></span>Married</div></label>

                          </div>
                        </div>
                      </div>
                      <div class="step-2__col">
                        <div class="dependent-parents">
                          <div class="dependent-parents__title">How many dependent parents do you have?</div>
                          <div class="dependent-parents__items">
                            <input type="radio" id="none" value="none" v-model="parentCount" />
                            <label for="none"><div class="item" :class="{active: parentCount === 'none'}"><span></span>None</div></label>

                            <input type="radio" id="oneParent" value="oneParent" v-model="parentCount" />
                            <label for="oneParent"><div class="item" :class="{active: parentCount === 'oneParent'}"><span></span>One</div></label>

                            <input type="radio" id="twoParent" value="twoParent" v-model="parentCount" />
                            <label for="twoParent"><div class="item" :class="{active: parentCount === 'twoParent'}"><span></span>Two</div></label>
                          </div>
                        </div>
                      </div>
                 </div>
        <div v-if="percent && picked" class="total-price__popup" :class="{ active: totalPopup }">
          <div class="total-price__popup-item">
            <div class="title">Current disability rating</div>
            <div class="total">{{ percent }}%</div>
            <span>*A bilateral factor of 10 has been applied.</span>
          </div>
          <div class="total-price__popup-item">
            <div class="title">Your monthly payment amount is</div>
            <div class="total">$ {{ prices[percent] + under18Count*68 + over18Count*68 }}</div>
            <div class="sendEmail">
              <button @click="openEmailPopup">Send results via Email</button>
            </div>
            <div @click="closeEmailPopup" v-if="emailPopupIsOpen">

            </div>
          </div>
        </div>
        <div class="images_block">
          <div class="images_block-item">
            <img src="../assets/images/secret.png" alt="">
            <div>
              <span>5 SECRETS TO WINNING YOUR VA CLAIM</span>
            </div>
          </div>
          <div class="images_block-item">
            <img src="../assets/images/rating.png" alt="">
            <div>
              <span>VA’S RATING RULES: EXPLAINED</span>
            </div>
          </div>
          <div class="images_block-item">
            <img src="../assets/images/roles.png" alt="">
            <div>
              <span>TDUI: A LOOPHOLE TO VA’S RATING RULES</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div :class="{active : emailPopupIsOpen}" class="email-form">
        <div class="modal-background">
          <div class="email-form-title">
            <span>Get Your Results Via Email</span>
          </div>
          <div class="email-form-inputs">
            <form action="" method="POST">
              <div class="email-input-item">
                <div>
                  <p>First Name</p>
                </div>
                <div>
                  <input type="text" name="name" v-model="formData.name">
                </div>
              </div>
              <div class="email-input-item">
                <div>
                  <p>Last name</p>
                </div>
                <div>
                  <input type="text" name="lastname" v-model="formData.lastName">
                </div>
              </div>
              <div class="email-input-item">
                <div>
                  <p>Email</p>
                </div>
                <div>
                  <input type="text" name="email" v-model="formData.email">
                </div>
              </div>
              <div class="email-input-item">
                <div>
                  <p>Email 2 (optional)</p>
                </div>
                <div>
                  <input type="text" name="email2" v-model="formData.email2">
                </div>
              </div>
              <div class="email-input-item">
                <div>
                  <p>Phone</p>
                </div>
                <div>
                  <input type="text" name="phone" v-model="formData.phone">
                </div>
              </div>
              <div class="email-input-item">
                <div>
                  <p>Message</p>
                </div>
                <div>
              <textarea name="message" v-model="formData.message">
              </textarea>
                </div>
                <div class="email-form-button">
                  <button @click.prevent="sendEmail">Submit</button>
                </div>
              </div>
            </form>
            </div>
        </div>
    </div>
    <div class="footer">
      <img src="../assets/images/footer.png" alt="">
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data(){
    return{
      totalPopup : true,
      under18Count: 0,
      over18Count: 0,
      isMarried: '',
      percentBlock: false,
      isChecked: false,
      emailPopupIsOpen: false,
      picked: '',
      percent: null,
      parentCount: '',
      prices: {
        "10": 152.64,
        "20": 301.74,
        "30": 467.39,
        "40": 673.28,
        "50": 958.44,
        "60": 1214.03,
        "70": 1529.95,
        "80": 1778.43,
        "90": 1998.52,
        "100": 3332.06
      },
      formData: {
        name: '',
        lastName: '',
        email: '',
        email2: '',
        phone: '',
        message: '',
      }
    }
  },
  methods: {
    async sendEmail(){
      const data = {
        formData: this.formData,
      }
      await axios.post('/api/sendEmail',data)
          .then((response) => {
            console.log(response)
          })
          .catch(e => {
            this.errors = e.response.data
          })
      this.emailPopupIsOpen = false
      this.formData = {}
    },
    openPopup() {
      this.totalPopup = !this.totalPopup
    },
    addAgeUnder18Count(){
      this.under18Count += 1
    },
    minusUnder18AgeCount(){
      if (this.under18Count !== 0){
        this.under18Count -= 1
      }
    },
    addAgeOver18Count(){
      this.over18Count += 1
    },
    minusOver18AgeCount(){
      if (this.over18Count !== 0){
        this.over18Count -= 1
      }
    },
    closePercentBlock(){
      this.percent = null
    },
    openEmailPopup(){
      this.percent = null
      this.emailPopupIsOpen = !this.emailPopupIsOpen
    },
    closeEmailPopup(){
      this.emailPopupIsOpen = false
    },
  }
}
</script>

<style lang="scss">

@import url('https://fonts.cdnfonts.com/css/open-sans');
input[type='radio'] {
  -webkit-appearance:none;
}

.calculator{
  .header{
    position: sticky;
    top: 0;
    z-index: 999;
    img{
      width: 100%;
      object-fit: cover;
      vertical-align: top;
    }
  }
  .body{
    max-width: 1050px;
    margin-left: auto;
    margin-right: auto;
    color : #06365E;
    font-family: 'Open Sans';
    .title{
      h1{
        text-align: center;
        color: #707070;
        font-size: 48px;
        font-weight: 800;
        margin-bottom: 24px;
        margin-top: 32px;
      }
      h2{
        font-size: 24px;
        font-weight: 700;
        line-height: 36px;
        margin-bottom: 12px;
      }
    }
    .step-1{
      background-color: #ffffff;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.04);
      border-radius: 19px;
      padding: 32px 24px;
      text-align: center;
      margin-bottom: 32px;
      .description{
        font-size: 18px;
        text-align: center;
        font-weight: 400;
      }
      &__headline{
        display: inline-flex;
        padding: 4px 0;
        border: 1px solid #06365E;
        margin-top: 24px;
        &-item{
          color :#06365E;
          width: 140px;
          height: 40px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          cursor: pointer;
          margin: 0 4px;
          &.active{
            background-color: #06365E;
            color: #ffffff;
          }
        }
      }
      &__body{
        margin-top: 20px;
        max-width: 541px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 4px;
        padding-right: 4px;
        .body-row{
          display: flex;
          margin-left: -4px;
          margin-right: -4px;
        }
        .body-col{
          max-width: 20%;
          flex-basis: 20%;
          padding-left: 4px;
          padding-right: 4px;
        }
        &-item{
          width: 102px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #06365E;
          font-size: 16px;
          margin-bottom: 16px;
          cursor: pointer;
          span{
            width: 14px;
            height: 14px;
            border-radius: 50%;
            border: 1px solid #06365E;
            margin-right: 11px;
          }
          &.active{
            background-color: #06365E;
            color: #ffffff;
            span{
              border: 1px solid #ffffff;
              background-color: #ffffff;
              box-shadow: 0px 0px 0px 3px #06365E inset;
              -webkit-box-shadow: 0px 0px 0px 3px #06365E inset;
              -moz-box-shadow: 0px 0px 0px 3px #06365E inset;
            }
          }
        }
      }
      &__footer{
        margin-top: 20px;
        .text{
          margin-top: 32px;
          margin-bottom: 16px;
        }
      }
      &__selected-items{
        max-width: 541px;
        margin-left: auto;
        margin-right: auto;
        padding-left: -4px;
        padding-right: -4px;
        display: flex;
        .item{
          background-color: #E2EEF3;
          padding: 8px 14px 8px 12px;
          display: inline-flex;
          align-items: center;
          margin: 0 4px;
          cursor: pointer;
          span{
            margin-left: 10px;
            width: 12px;
            height: 12px;
            position: relative;
            border: 1px solid #06365E;
            border-radius: 50%;
            &:before{
              display: block;
              content: '+';
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate( -50%, -50%) rotate(45deg);
              font-size: 15px;
              color: #06365E;
            }
          }
        }
      }
    }
    .step-2{
      background-color: #ffffff;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.04);
      text-align: center;
      margin-bottom: 32px;
      position: relative;
      .description{
        font-size: 18px;
        text-align: center;
        line-height: 27px;
        font-weight: 400;
      }
      &__row{
        display: flex;
        justify-content: space-between;
        margin-left: -16px;
        margin-right: -16px;
      }
      &__col{
        max-width: 50%;
        flex-basis: 50%;
        padding-left: 16px;
        padding-right: 16px;
        margin-bottom: 32px;
      }
      .children-item{
        text-align: left;
        &__title{
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 12px;
        }
        &__count{
          display: inline-flex;
          align-items: center;
          justify-content: space-between;
          height: 40px;
          border: 1px solid #06365E;

          span{
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 46px;
            height: 40px;
            &:nth-child(1){
              position: relative;
              display: block;
              &:before{
                content: '';
                display: block;
                width: 16px;
                height: 1px;
                background: #B8B8B8;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }
            }
          }
          .count-reg{
            font-size: 24px;
            cursor: pointer;
          }
        }
      }
      .marital-status{
        text-align: left;
        &__title{
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 12px;
        }
        &__items{
          display: flex;
          align-items: center;
          .item{
            width: 126px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #06365E;
            margin-right: 16px;
            cursor: pointer;
            span{
              width: 14px;
              height: 14px;
              border-radius: 50%;
              border: 1px solid #06365E;
              margin-right: 11px;
            }
            &.active{
              background-color: #06365E;
              color: #ffffff;
              span{
                border: 1px solid #ffffff;
                background-color: #ffffff;
                box-shadow: 0px 0px 0px 3px #06365E inset;
                -webkit-box-shadow: 0px 0px 0px 3px #06365E inset;
                -moz-box-shadow: 0px 0px 0px 3px #06365E inset;
              }
            }
          }
        }
      }
      .dependent-parents{
        text-align: left;
        &__title{
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 12px;
        }
        &__items{
          display: flex;
          align-items: center;
          .item{
            width: 107px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #06365E;
            margin-right: 16px;
            cursor: pointer;
            span{
              width: 14px;
              height: 14px;
              border-radius: 50%;
              border: 1px solid #06365E;
              margin-right: 11px;
            }
            &.active{
              background-color: #06365E;
              color: #ffffff;
              span{
                border: 1px solid #ffffff;
                background-color: #ffffff;
                box-shadow: 0px 0px 0px 3px #06365E inset;
                -webkit-box-shadow: 0px 0px 0px 3px #06365E inset;
                -moz-box-shadow: 0px 0px 0px 3px #06365E inset;
              }
            }
          }
        }
      }
      .aid-attendance{
        text-align: left;
        &__title{
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 12px;
        }
        &__items{
          display: flex;
          align-items: center;
          .item{
            width: 92px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #06365E;
            margin-right: 16px;
            cursor: pointer;
            span{
              width: 14px;
              height: 14px;
              border-radius: 50%;
              border: 1px solid #06365E;
              margin-right: 11px;
            }
            &.active{
              background-color: #06365E;
              color: #ffffff;
              span{
                border: 1px solid #ffffff;
                background-color: #ffffff;
                box-shadow: 0px 0px 0px 3px #06365E inset;
                -webkit-box-shadow: 0px 0px 0px 3px #06365E inset;
                -moz-box-shadow: 0px 0px 0px 3px #06365E inset;
              }
            }
          }
        }
      }
    }
    .information{
      margin-top: 72px;
      margin-bottom: 128px;
      &-title{
        h2{
          font-size: 32px;
          color: #707070;
          font-weight: 700;
          text-align: center;
          margin-bottom: 24px;
          line-height: 35px;
        }
      }
      &-content{
        h3{
          font-size: 18px;
          line-height: 27px;
          font-weight: 700;
          margin-bottom: 8px;
        }
        p{
          margin-bottom: 24px;
          line-height: 24px;
        }
      }
    }
    .total-price__popup{
      position: fixed;
      bottom: 0;
      width: 60%;
      z-index: 1;
      background-color: #E2EEF3;
      display: none;
      border-radius: 19px 19px 0px 0px;
      padding: 32px 24px 24px;
      &.active{
        display: flex;
      }
      >div{
        max-width: 50%;
        flex-basis: 50%;
        &:last-child{
          border-left: 1px solid #8DBAD1;
        }
        &:first-child{
          border-right: 1px solid #8DBAD1;
        }
      }
      &-item{
        text-align: center;
        .title{
          font-size: 18px;
          margin-bottom: 8px;
        }
        .total{
          font-size: 32px;
          font-weight: 700;
          margin-bottom: 8px;
        }
        .sendEmail{
          background-color: #B10101;
          color: white;
          border-radius: 8px;
          width: 215px;
          text-align: center;
          margin-left: 205px;
          button{
            margin: 8px 26px;
            background: none;
            color: inherit;
            border: none;
            padding: 0;
            font: inherit;
            cursor: pointer;
            outline: inherit;
          }
        }
        span{
          color: #1D1D1D;
          font-size: 14px;
        }
      }
    }
  }
  .footer{
    img{
      width: 100%;
      object-fit: cover;
      vertical-align: top;
    }
  }
  .images_block{
    margin-top: 250px;
    display: flex;
    justify-content: center;
    position: relative;
    .images_block-item{
      width: 517px;
      margin-left: 31px;
      background: #1D1D1D;
      img{
        width: 100%;
        object-fit: cover;
        opacity: 0.5;
      }
      div{
        position: absolute;
        top: 38%;
        color: white;
        width: 300px;
        margin-left: 10px;
        margin-right: 10px;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 36px;
      }
    }
  }
  .email-form{
    display: none;
    &.active{
      display: flex;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 99999;
      background-color: rgba(255, 255, 255, .8);
      box-shadow: 0 10px 15px #0b31581a;
      align-items: center;
      justify-content: center;
        .modal-background{
          width: 100%;
          max-width: 630px;
          text-align: center;
          background-color: #ffffff;
          padding: 2rem;
          box-shadow: 0px 10px 15px #0b31581a;
          border: 1px solid #E8E8E8;
          border-radius: 10px;
          position: relative;
          .email-form-title {
            margin-left: 24px;

            span {
              font-style: normal;
              font-weight: 700;
              font-size: 32px;
              line-height: 35px;
              text-align: center;
              color: #06365E;
            }
          }

          .email-form-inputs {
            .email-form-button {
              margin-top: 40px;
              display: flex;
              justify-content: center;
            }

            .email-input-item {
              margin-left: 24px;
              margin-bottom: 24px;

              span {

                font-style: normal;
                margin-bottom: 5px;
                float: left;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: #1D1D1D;
              }

              input {
                width: 454px;
                height: 48px;
                left: 733px;
                top: 184px;
                background: #FFFFFF;
                border: 1px solid #B7D5E3;
              }

              textarea {
                width: 454px;
                height: 132px;
                background: #FFFFFF;
                border: 1px solid #B7D5E3;
              }

              button {
                text-align: center;
                width: 117px;
                height: 40px;
                color: white;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                background: #B10101;
              }
            }
          }
        }

      }
    }
}
</style>
